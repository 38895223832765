import { t } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { Helmet } from "react-helmet-async";

import { HeroSection } from "./sections/hero";

export const HomePage = () => {
  const { i18n } = useLingui();

  return (
    <main className="relative isolate bg-background">
      <Helmet prioritizeSeoTags>
        <html lang={i18n.locale} />

        <title>
          {t`Reactive Resume`} - {t`Workinglife.com | With you from graduation to retirement`}
        </title>

        <meta
          name="description"
          content="From graduation to retirement. We offer you guidance, tools, stories and news to help you at every moment in your career."
        />
      </Helmet>

      <HeroSection />
    </main>
  );
};
